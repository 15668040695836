<template>
    <PModal :can-cancel="canCancel" :footer-actions="actions" slot-class="p-0">
        <template #title
            ><span> {{ $l("platon.choose_organization", "Корхонани танланг") }} </span></template
        >

        <b-overlay :show="loading">
            <div v-if="organizations.length > 0" class="org__container">
                <div class="d-flex justify-content-between align-items-stretch px-2 mt-1 mb-2 w-100 org__search">
                    <input
                        @input="orgSearchChange"
                        class="form-control org__search--input mx-2 flex-1"
                        :placeholder="$l('platon.search_dots', 'Излаш...')"
                    />
                </div>
                <div v-if="filterOrganization.length">
                    <div
                        :key="org.tin || org.pinfl"
                        v-for="org in filterOrganization"
                        class="org__search--item d-flex align-items-center"
                        @click="clickOrg(org)"
                    >
                        <div class="flex-grow-1 mr-2">
                            <div v-if="org.tin" class="tg-1">{{ $l("platon.eimzo_tin", "СТИР") }} {{ org.tin }}</div>

                            <div v-else-if="org.pinfl" class="tg-1">
                                {{ $l("platon.eimzo_pinfl", "ЖШШИР") }} {{ org.pinfl }}
                            </div>

                            <div class="font-weight-bold">{{ org.name }}</div>
                        </div>

                        <div
                            v-if="(org.tin || org.pinfl).toString() === currentOrg"
                            class="text-success"
                            style="font-size: 22px"
                        >
                            <i class="fas fa-check-circle"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center mt-4" v-else>
                <p>{{ $l("platon.organizations_not_found", "Корхоналар топилмади") }}</p>
            </div>
        </b-overlay>
    </PModal>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import debounce from "lodash.debounce"

export default {
    name: "PickUserOrganization",

    props: {
        organizations: {
            type: Array
        },

        onSelected: {
            type: Function,
            default: () => () => {}
        },

        canCancel: {
            type: Boolean
        },

        orgId: {
            type: [String, Number],
            require: false
        },

        searchBy: {
            type: String,
            default: "name"
        }
    },

    data() {
        return {
            loading: false,
            searchValue: ""
        }
    },

    computed: {
        ...mapGetters({
            user: "platon/user"
        }),

        /**
         * @return {ModalAction[]}
         */
        actions() {
            if (this.canCancel) {
                return [{ text: this.$l("platon.cancel", "Бекор қилиш") }]
            } else {
                return [
                    {
                        text: this.$l("platon.logout", "Тизимдан чиқиш"),
                        icon: "fa fa-sign-out-alt",
                        handler: () => {
                            this.$store.dispatch("platon/logout")

                            return false
                        }
                    }
                ]
            }
        },

        currentOrg() {
            if (this.orgId) return this.orgId.toString()
            return this.user && this.user.orgId && this.user.orgId.toString()
        },

        filterOrganization() {
            return this.organizations.filter((org) =>
                org[this.searchBy].toString().toLowerCase().includes(this.searchValue.toLowerCase())
            )
        }
    },

    methods: {
        ...mapActions({
            switchOrganization: "platon/switchOrganization"
        }),

        /**
         * @param {{name: string, tin: ?string, pinfl: ?string}} org
         */
        async clickOrg(org) {
            try {
                this.loading = true
                let orgId = org.tin || org.pinfl
                await this.switchOrganization({ orgId })

                this.onSelected(orgId)
                this.$emit("close")
            } finally {
                this.loading = false
            }
        },

        orgSearchChange: debounce(function (e) {
            this.searchValue = e.target.value
        }, 500)
    }
}
</script>

<style lang="scss" scoped>
.org__container {
    position: relative;
}
.org__search {
    top: 0px;
    position: sticky;
    background-color: var(--pl-input-bg);

    &--item {
        border-bottom: 2px solid var(--pl-dialog-border-color);
        padding: 12px;
        cursor: pointer;

        &:hover {
            background: rgba(0, 0, 0, 0.05);
        }
    }
}
</style>
